import React from "react";

const Index = () => {
  return (
    <>
      <h1>this is sidebar for private layout</h1>
    </>
  );
};

export default Index;
