import React from "react";
import ContactInfo from "../../Pages/HelpfulInformation/ContactInfo";
import OderOfServices from "../../Pages/HelpfulInformation/OderOfServices";
import PrivacyPolicy from "../../Pages/HelpfulInformation/PrivacyPolicy";
import TermsOfUse from "../../Pages/HelpfulInformation/TermsOfUse";
import UseMarketplace from "../../Pages/HelpfulInformation/UseMarketplace";
import Registration from "../../Pages/LoginAndRegistration/Registration";

const Cart = React.lazy(() => import("../../Pages/Cart/CartPage"));
const CartPage2 = React.lazy(() =>
  import("../../Pages/PostpondsGoods/PostponedGoods")
);
const CartPage3 = React.lazy(() => import("../../Pages/Cart/NoOrders"));
const CartPage4 = React.lazy(() => import("../../Pages/Cart"));
const Checkout = React.lazy(() => import("../../Pages/Checkout"));
const Children = React.lazy(() => import("../../Pages/Catalog/Children"));
const Cosmatics = React.lazy(() => import("../../Pages/Product/Cosmatics"));
const General = React.lazy(() => import("../../Pages/Catalog/General"));
const Home = React.lazy(() => import("../../Pages/Home"));
const Jackets = React.lazy(() => import("../../Pages/Product/NonPromotional"));
const Men = React.lazy(() => import("../../Pages/Catalog/Men"));
const NoResult = React.lazy(() => import("../../Pages/Cart/NoResultSearch"));
const NotFound = React.lazy(() => import("../../Pages/others/NotFound"));
const Order = React.lazy(() => import("../../Pages/Order"));
const OutletShop = React.lazy(() => import("../../Pages/OutletShop"));
const Payment = React.lazy(() => import("../../Pages/Payment"));
const ProductDetails = React.lazy(() => import("../../Pages/ProductDetails"));
const PostponedGoods = React.lazy(() =>
  import("../../Pages/PostpondsGoods/PostponedGoods")
);
const SearchProduct = React.lazy(() =>
  import("../../Pages/Product/SearchProducts")
);
const UserProfile = React.lazy(() => import("../../Pages/Profile"));
const Women = React.lazy(() => import("../../Pages/Catalog/Women"));
const HelpfulInformation = React.lazy(() =>
  import("../../Pages/HelpfulInformation")
);
const Favorites = React.lazy(() => import("../../Pages/Favorites"));
const Outlet = React.lazy(() => import("../../Pages/Outlet"));
const Academy = React.lazy(() => import("../../Pages/Academy"));
const B2BPortal = React.lazy(() => import("../../Pages/B2BPortal"));
const ShowSinglePartner = React.lazy(() =>
  import("../../Pages/B2BPortal/Partner/SinglePartner")
);
const Login = React.lazy(() => import("../../Pages/LoginAndRegistration"));
// const ShowSingleNews = React.lazy(() =>
//   import("../../Pages/B2BPortal/News/ShowSingleNews")
// );
const MyBusiness = React.lazy(() => import("../../Pages/MyBusniess"));
const ContactInformation = React.lazy(() =>
  import("../../Pages/ContactInformation")
);
const CardProduct = React.lazy(() => import("../../Pages/CardProduct"));

const routes = [
  // please place all public routes with key tpye: public
  {
    path: "/",
    exact: true,
    name: "Home",
    Component: Home,
    type: "public",
  },
  {
    path: "/not-found",
    exact: true,
    name: "Not Found",
    Component: NotFound,
    type: "public",
  },
  {
    path: "/catalog-children",
    exact: true,
    name: "Children",
    Component: Children,
    type: "public",
  },
  {
    path: "/catalog-men",
    exact: true,
    name: "Men",
    Component: Men,
    type: "public",
  },
  {
    path: "/catalog-women",
    exact: true,
    name: "Women",
    Component: Women,
    type: "public",
  },
  {
    path: "/catalog-general",
    exact: true,
    name: "General",
    Component: General,
    type: "public",
  },
  {
    path: "/product-cosmatic",
    exact: true,
    name: "Cosmatics",
    Component: Cosmatics,
    type: "public",
  },
  {
    path: "/product-jackets",
    exact: true,
    name: "Jackets",
    Component: Jackets,
    type: "public",
  },
  {
    path: "/checkout",
    exact: true,
    name: "Jackets",
    Component: Checkout,
    type: "public",
  },
  {
    path: "/payment",
    exact: true,
    name: "Payment",
    Component: Payment,
    type: "public",
  },
  {
    path: "/order",
    exact: true,
    name: "Order",
    Component: Order,
    type: "public",
  },
  {
    path: "/searchProduct",
    exact: true,
    name: "searchProduct",
    Component: SearchProduct,
    type: "public",
  },
  {
    path: "/cart-2",
    exact: true,
    name: "Cart",
    Component: CartPage2,
    type: "public",
  },
  {
    path: "/cart-3",
    exact: true,
    name: "Cart",
    Component: CartPage3,
    type: "public",
  },
  {
    path: "/cart-4",
    exact: true,
    name: "Cart",
    Component: CartPage4,
    type: "public",
  },

  {
    path: "/cart",
    exact: true,
    name: "Cart",
    Component: Cart,
    type: "public",
  },
  {
    path: "/product-details",
    exact: true,
    name: "Home",
    Component: ProductDetails,
    type: "public",
  },
  {
    path: "/outlet-shop",
    exact: true,
    name: "Home",
    Component: OutletShop,
    type: "public",
  },
  {
    path: "/postponds-goods",
    exact: true,
    name: "PostpondsGoods",
    Component: PostponedGoods,
    type: "public",
  },
  {
    path: "/no-result-search",
    exact: true,
    name: "NO Result Search",
    Component: NoResult,
    type: "public",
  },
  {
    path: "/userProfile/:tabID",
    exact: true,
    name: "userProfile",
    Component: UserProfile,
    type: "public",
  },
  {
    path: "/helpful-information/:tabID",
    exact: true,
    name: "HelpfulInformation",
    Component: HelpfulInformation,
    type: "public",
  },
  {
    path: "/favorites",
    exact: true,
    name: "Favorites",
    Component: Favorites,
    type: "public",
  },
  {
    path: "/brands",
    exact: true,
    name: "Brands",
    Component: Outlet,
    type: "public",
  },
  {
    path: "/academy",
    exact: true,
    name: "Academy",
    Component: Academy,
    type: "public",
  },
  {
    path: "/b2bportal/:tabId",
    exact: true,
    name: "b2bPortal",
    Component: B2BPortal,
    type: "public",
  },
  {
    path: "/showSinglePartner/:partnerId",
    exact: true,
    name: "ShowSinglePartner",
    Component: ShowSinglePartner,
    type: "public",
  },
  {
    path: "/myBusiness",
    exact: true,
    name: "MyBusiness",
    Component: MyBusiness,
    type: "public",
  },
  {
    path: "/login",
    exact: true,
    name: "login",
    Component: Login,
    type: "public",
  },
  {
    path: "/registration",
    exact: true,
    name: "registration",
    Component: Registration,
    type: "public",
  },
  {
    path: "/contact",
    exact: true,
    name: "Contact",
    Component: ContactInfo,
    type: "public",
  },
  {
    path: "/termsofuse",
    exact: true,
    name: "Terms of use",
    Component: TermsOfUse,
    type: "public",
  },
  {
    path: "/privacypolicy",
    exact: true,
    name: "Privacy Policy",
    Component: PrivacyPolicy,
    type: "public",
  },
  {
    path: "/marketplace",
    exact: true,
    name: "How to use marketplace",
    Component: UseMarketplace,
    type: "public",
  },
  {
    path: "/oderservices",
    exact: true,
    name: "The order of using the services",
    Component: OderOfServices,
    type: "public",
  },
  {
    path: "/contactInformation",
    exact: true,
    name: "ContactInformation",
    Component: ContactInformation,
    type: "public",
  },
  {
    path: "/card-product",
    exact: true,
    name: "CardProduct",
    Component: CardProduct,
    type: "public",
  },
];

export default routes;
