import React, { useEffect, useState } from "react";
import {
  Dropdown,
  NavDropdown,
  Row,
  Col,
  Nav,
  Navbar,
  Container,
  NavItem,
} from "react-bootstrap";
import { useNavigate } from "react-router-dom";

// import ConnectLogo from "../../../assests/icons/ConnectLogo";
import ConnectLogo from "../../../assests/images/connect_logo.png";
import OrdersIcon from "../../../assests/icons/OrdersIcon";
import Emailicons from "../../../assests/icons/Emailicon";
import FavouriteIcon from "../../../assests/icons/FavouriteIcon";
import CartIcon from "../../../assests/icons/CartIcon";
import ProfileIcon from "../../../assests/icons/ProfileIcon";
import SearchIcon from "../../../assests/icons/SearchIcon";
import UserAuth from "../../../Components/auth-pop-up/UserAuth";

import "./header.css";
import ArrowDownIcon from "../../../assests/icons/ArrowDownIcon";

const dummySearch = ["jack", "jill", "Ant", "bat", "mobile", "cat"];

const Index = () => {
  const [searchItem, setSearchItem] = useState("");
  const [suggestion, setSuggestion] = useState([]);
  const [show, setShow] = useState(false);
  const handleShow = () => setShow(true);
  const [language, setLanguage] = useState("Ru");
  // const [showDropdownHover, setShowDropdownHover] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (searchItem !== "") {
      setSuggestion([
        ...dummySearch.filter((f) =>
          f.toLowerCase().includes(searchItem.toLowerCase())
        ),
      ]);
    }
  }, [searchItem]);

  //   const showDropdown = (e)=>{
  //     setShowDropdownHover(!showDropdownHover);
  // }
  // const hideDropdown = e => {
  //     setShowDropdownHover(false);
  // }

  const HoverDropDown = ({ children, ...rest }) => {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
      <NavDropdown
        {...rest}
        show={showDropdown}
        onMouseEnter={() => setShowDropdown(true)}
        onMouseLeave={() => setShowDropdown(false)}
      >
        {children}
      </NavDropdown>
    );
  };

  const CustomToggle = React.forwardRef(({ children, onClick, className }) => {
    return (
      <div
        className={className}
        onClick={(e) => {
          e.preventDefault();
          onClick(e);
        }}
      >
        {children}
        {/* &#x25bc; */}
      </div>
    );
  });

  const CustomMenu = React.forwardRef(
    ({ children, style, className, "aria-labelledby": labeledBy }, ref) => {
      return (
        <div
          ref={ref}
          style={style}
          className={className}
          aria-labelledby={labeledBy}
        >
          <ul className="list-unstyled">
            {React.Children.toArray(children).filter(
              (child) => child.props.children
            )}
          </ul>
        </div>
      );
    }
  );

  return (
    <>
      <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
        <Container>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              {/* <Nav.Link
                className="main-header-link"
                href="#">
                Каталог
              </Nav.Link> */}
              <HoverDropDown title="Каталог" id="collasible-nav-dropdown">
                <NavDropdown.Item onClick={() => navigate("/catalog-general")}>
                  General
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/catalog-women")}>
                  Women
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/catalog-men")}>
                  Men
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/catalog-children")}>
                  Children
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/outlet-shop")}>
                  shop outlet
                </NavDropdown.Item>
              </HoverDropDown>
              {/* <Nav.Link
                className="main-header-link"
                href="#"
                onClick={() => navigate("/b2bportal")}
              >
                B2B портал
              </Nav.Link> */}
              <HoverDropDown
                title="B2B портал"
                // onClick={(e) => {}}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item onClick={() => navigate("/b2bportal/0")}>
                  Мой бизнес
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/1")}>
                  Мои заказы
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/2")}>
                  Добавить продукт
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/3")}>
                  Партнеры
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/4")}>
                  Агенты
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/5")}>
                  Вакансии
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/6")}>
                  Новости
                </NavDropdown.Item>
                <NavDropdown.Item onClick={() => navigate("/b2bportal/7")}>
                  Выставки и форумы
                </NavDropdown.Item>
              </HoverDropDown>
              {/* <NavDropdown title="Listof Pages" id="collasible-nav-dropdown">
                <NavDropdown.Item href="product-cosmatic">
                  Cosmatic
                </NavDropdown.Item>
                <NavDropdown.Item href="product-jackets">
                  jackets
                </NavDropdown.Item>
                <NavDropdown.Item href="product-shoes">shoes</NavDropdown.Item>
              </NavDropdown> */}
              <Nav.Link
                className="main-header-link"
                href="#"
                onClick={() => navigate("/academy")}
              >
                Академия
              </Nav.Link>
              {/* <HoverDropDown
                title="Академия"
                onClick={() => navigate("/academy")}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="cart">Cart 1</NavDropdown.Item>
                <NavDropdown.Item href="cart-2">Cart 2</NavDropdown.Item>
                <NavDropdown.Item href="cart-3">Cart 3</NavDropdown.Item>
                <NavDropdown.Item href="cart-4">Cart 4</NavDropdown.Item>
              </HoverDropDown> */}
              <Nav.Link
                className="main-header-link"
                href="#"
                onClick={() => navigate("/brands")}
              >
                Бренды
              </Nav.Link>
              {/* <HoverDropDown
                title="Бренды"
                onClick={() => navigate("/brands")}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item href="checkout">chekout</NavDropdown.Item>
                <NavDropdown.Item href="payment">
                  Payment system
                </NavDropdown.Item>
                <NavDropdown.Item href="order">order</NavDropdown.Item>
                <NavDropdown.Item href="no-result-search">
                  No result
                </NavDropdown.Item>
              </HoverDropDown> */}
              <Nav.Link href="#pricing">Акции</Nav.Link>
            </Nav>
            <Nav>
              {/* <Nav.Link href="#"></Nav.Link> */}
              <HoverDropDown
                title={`Язык: ${language}`}
                id="collasible-nav-dropdown"
              >
                <NavDropdown.Item
                  href="#action/3.1"
                  onClick={() => setLanguage("Ru")}
                >
                  Ru
                </NavDropdown.Item>
                <NavDropdown.Item
                  href="#action/3.2"
                  onClick={() => setLanguage("En")}
                >
                  En
                </NavDropdown.Item>
              </HoverDropDown>

              <div href="#memes" className="user-mail" onClick={handleShow}>
                <div className="me-3">
                  <Emailicons />
                </div>
                <div>support@connect 24.com</div>
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <Row className="logobar flex-wrap">
        <Col lg="2" md="3" sm="4" xs="12">
          <span className="c-p" onClick={() => navigate("/")}>
            <img src={ConnectLogo} alt="logo" />
          </span>
        </Col>
        <Col
          lg="4"
          md="6"
          sm="8"
          xs="12"
          className="d-flex align-items-center h-100"
        >
          <div className="search-container">
            <div className="main-search">
              <span className="search-icon">
                <SearchIcon />
              </span>
              <input
                className="search-input-header"
                type="text"
                value={searchItem}
                placeholder="Что вы ищете?"
                onChange={(e) => setSearchItem(e.target.value)}
              />
              <div className="v-border" />
              {suggestion.length >= 0 && searchItem !== "" && (
                <div className="search-suggestions-container">
                  <ul className="suggestion-item-ul">
                    {suggestion.map((m) => (
                      <li
                        className="suggestion-item"
                        onClick={() => {
                          console.log(m);
                          setSearchItem(m);
                          navigate("/searchProduct", {
                            state: { productId: m },
                          });
                          setSuggestion([]);
                        }}
                      >
                        {m}
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              <Dropdown>
                <Dropdown.Toggle
                  as={CustomToggle}
                  id="dropdown-custom-components"
                  className="dropdown-title"
                >
                  Выбрать категорию
                  <span className="dropdown-icon">
                    <ArrowDownIcon />
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu
                  className="dropdow-item-container"
                  as={CustomMenu}
                >
                  <Dropdown.Item eventKey="1" className="dropdown-item">
                    Шапка
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="2" className="dropdown-item">
                    Шапка женская
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="3" className="dropdown-item">
                    Шапка женская Metka
                  </Dropdown.Item>
                  <Dropdown.Item eventKey="4" className="dropdown-item">
                    Шапка детская
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              {/* <DropdownButton
                variant="outline-secondary"
                title="Выбрать категорию"
                id="input-group-dropdown-4"
                align="start"
                className="header-drop-down"
              >
                <Dropdown.Item href="#">Action</Dropdown.Item>
                <Dropdown.Item href="#">Another action</Dropdown.Item>
                <Dropdown.Item href="#">Something else here</Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item href="#">Separated link</Dropdown.Item>
              </DropdownButton> */}
            </div>
          </div>
        </Col>
        <Col
          lg="6"
          md="12"
          sm="12"
          xs="12"
          className="d-flex flex-wrap align-items-center justify-content-between px-4"
        >
          <div className="d-flex flex-wrap align-items-center">
            <div className="m-2 d-flex align-items-center">
              <span
                className="c-p"
                onClick={() => navigate(`/userProfile/${1}`)}
              >
                <OrdersIcon />
              </span>
            </div>
            <div
              className="m-2 c-p"
              onClick={() => navigate(`/userProfile/${1}`)}
            >
              Заказы
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="m-2 d-flex align-items-center">
              <span className="c-p" onClick={() => navigate("/favorites")}>
                <FavouriteIcon />
              </span>
            </div>
            <div className="m-1 c-p" onClick={() => navigate("/favorites")}>
              Избранное
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="m-2 d-flex align-items-center">
              <span className="c-p" onClick={() => navigate("/cart-4")}>
                <CartIcon />
              </span>
            </div>
            <div className="m-2 c-p" onClick={() => navigate("/cart-4")}>
              Корзина
            </div>
          </div>
          <div className="d-flex flex-wrap align-items-center">
            <div className="m-2 d-flex align-items-center">
              <span
                className="c-p"
                onClick={() => navigate(`/userProfile/${4}`)}
              >
                <ProfileIcon />
              </span>
            </div>
            <div
              className="m-2 c-p"
              onClick={() => navigate(`/userProfile/${4}`)}
            >
              Профиль
            </div>
          </div>
        </Col>
        <UserAuth isOpen={show} setClose={setShow} />
      </Row>
    </>
  );
};

export default Index;
