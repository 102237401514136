import React, { useState } from "react";
import "./userAuth.css";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import UserRegistration from "../user-registration-pop-up/UserRegistration";
import Vector from "../../assests/icons/Vector";
import CrossIcon from "../../assests/icons/CrossIcon";
import GoogleIcon from "../../assests/icons/GoogleIcon";
import LinkedinIcon from "../../assests/icons/LinkedinIcon";

export default function UserAuth({ isOpen, setClose }) {
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});
  const [showRegistration, setShowRegistration] = useState(false);

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const { email, password, remember } = form;
    const newError = {};

    if (!email || email === "") newError.email = "Please enter your email";
    else if (
      !/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
        email
      )
    )
      newError.email = "Please enter the proper email";
    if (!password || password === "")
      newError.password = "Please enter your password";
    // if(!remember || remember === '') newError.remember = "Please check the checkbox"

    return newError;
  };

  const handleSignIn = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
    }
  };

  const handleClose = () => setClose(false);

  const openRegistration = () => {
    setClose(false);
    setShowRegistration(true);
  };

  return (
    <div>
      <Modal.Dialog>
        <Modal
          show={isOpen}
          onHide={handleClose}
          dialogClassName="pop-up"
          centered
        >
          <div className="pop-up-header">
            <div className="pop-up-title">Вход в аккаунт</div>
            <div className="pop-up-close-icon-desktop" onClick={handleClose}>
              <Vector />
            </div>
            <div className="pop-up-close-icon-mobile" onClick={handleClose}>
              <CrossIcon />
            </div>
          </div>
          <div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label bsPrefix="form-label">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={form.email}
                  onChange={(e) => setField("email", e.target.value)}
                  isInvalid={!!errors.email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <div className="pop-up-header">
                  <Form.Label>Пароль</Form.Label>
                  <Form.Label>
                    <a href="#">Забыли пароль?</a>
                  </Form.Label>
                </div>
                <Form.Control
                  type="password"
                  value={form.password}
                  onChange={(e) => setField("password", e.target.value)}
                  isInvalid={!!errors.password}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 form-check-box-label"
                controlId="formBasicCheckbox"
              >
                <Form.Check
                  type="checkbox"
                  value={form.remember}
                  onChange={(e) => setField("remember", e.target.checked)}
                  isInvalid={!!errors.remember}
                  label="Запомнить меня"
                />
              </Form.Group>
              <div className="user-sign-button">
                <Button type="submit" onClick={handleSignIn}>
                  Войти в аккаунт
                </Button>
              </div>
            </Form>
          </div>
          <div className="social-networks-line">
            <div className="divider"></div>
            <div className="social-networks">или продолжить через соцсети</div>
            <div className="divider"></div>
          </div>
          <div className="social-networks-buttons">
            <Button className="m-1 google-button">
              <GoogleIcon />
            </Button>
            <Button className="m-1 linkedin-button">
              <LinkedinIcon />
            </Button>
          </div>
          <div className="new-user-login" onClick={openRegistration}>
            Нет аккаунта? &nbsp;
            <span className="new-user-login-link">Зарегистрируйтесь</span>
          </div>
        </Modal>
      </Modal.Dialog>
      <UserRegistration
        isOpen={showRegistration}
        setCloseRegistration={setShowRegistration}
        setCloseSignIn={setClose}
      />
    </div>
  );
}
