
const Vector = () => {
  return (
    <div>
        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M12.9999 0.999818C12.8124 0.812347 12.5581 0.707031 12.2929 0.707031C12.0278 0.707031 11.7735 0.812347 11.5859 0.999818L6.99994 5.58582L2.41394 0.999818C2.22641 0.812347 1.9721 0.707031 1.70694 0.707031C1.44178 0.707031 1.18747 0.812347 0.99994 0.999818C0.812469 1.18735 0.707153 1.44165 0.707153 1.70682C0.707153 1.97198 0.812469 2.22629 0.99994 2.41382L5.58594 6.99982L0.99994 11.5858C0.812469 11.7733 0.707153 12.0277 0.707153 12.2928C0.707153 12.558 0.812469 12.8123 0.99994 12.9998C1.18747 13.1873 1.44178 13.2926 1.70694 13.2926C1.9721 13.2926 2.22641 13.1873 2.41394 12.9998L6.99994 8.41382L11.5859 12.9998C11.7735 13.1873 12.0278 13.2926 12.2929 13.2926C12.5581 13.2926 12.8124 13.1873 12.9999 12.9998C13.1874 12.8123 13.2927 12.558 13.2927 12.2928C13.2927 12.0277 13.1874 11.7733 12.9999 11.5858L8.41394 6.99982L12.9999 2.41382C13.1874 2.22629 13.2927 1.97198 13.2927 1.70682C13.2927 1.44165 13.1874 1.18735 12.9999 0.999818Z" fill="white"/>
        </svg>
    </div>
  )
}

export default Vector;