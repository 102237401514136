const GoogleIcon = () => {
  return (
    <div>
        <svg width="108" height="32" viewBox="0 0 108 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M68.0014 16.3114C68.0014 15.1603 67.9061 14.3203 67.6998 13.4492H54.2871V18.6447H62.1601C62.0014 19.9359 61.1442 21.8803 59.2394 23.1869L59.2127 23.3609L63.4536 26.5805L63.7474 26.6092C66.4458 24.167 68.0014 20.5736 68.0014 16.3114Z" fill="#4285F4"/>
            <path d="M54.2863 30.0008C58.1434 30.0008 61.3814 28.7562 63.7466 26.6096L59.2386 23.1873C58.0323 24.0117 56.4132 24.5873 54.2863 24.5873C50.5086 24.5873 47.3023 22.1451 46.1593 18.7695L45.9918 18.7835L41.5821 22.128L41.5244 22.2851C43.8736 26.8584 48.699 30.0008 54.2863 30.0008Z" fill="#34A853"/>
            <path d="M46.1601 18.768C45.8585 17.8969 45.684 16.9635 45.684 15.9991C45.684 15.0346 45.8585 14.1013 46.1443 13.2302L46.1363 13.0447L41.6713 9.64648L41.5252 9.71458C40.557 11.6124 40.0015 13.7435 40.0015 15.9991C40.0015 18.2546 40.557 20.3857 41.5252 22.2835L46.1601 18.768Z" fill="#FBBC05"/>
            <path d="M54.2864 7.4133C56.9689 7.4133 58.7784 8.54885 59.8102 9.4978L63.8419 5.64C61.3658 3.38445 58.1435 2 54.2864 2C48.699 2 43.8736 5.1422 41.5244 9.71549L46.1435 13.2311C47.3023 9.85555 50.5086 7.4133 54.2864 7.4133Z" fill="#EB4335"/>
        </svg>
    </div>
  )
}

export default GoogleIcon;