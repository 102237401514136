const Linkedin = () => {
  return (
    <div>
      <svg
        width="108"
        height="32"
        viewBox="0 0 108 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M48.3072 8.41736C48.3072 9.75242 47.1192 10.8347 45.6536 10.8347C44.1881 10.8347 43 9.75242 43 8.41736C43 7.08229 44.1881 6 45.6536 6C47.1192 6 48.3072 7.08229 48.3072 8.41736Z"
          fill="white"
        />
        <path d="M43.3629 12.6116H47.899V26H43.3629V12.6116Z" fill="white" />
        <path
          d="M55.2021 12.6116H50.666V26H55.2021C55.2021 26 55.2021 21.7851 55.2021 19.1498C55.2021 17.568 55.7566 15.9793 57.9691 15.9793C60.4695 15.9793 60.4544 18.0493 60.4428 19.653C60.4275 21.7492 60.4639 23.8884 60.4639 26H65V18.9339C64.9616 14.422 63.7545 12.343 59.7835 12.343C57.4253 12.343 55.9634 13.3858 55.2021 14.3293V12.6116Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default Linkedin;
