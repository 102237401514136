import React from "react";

import CallIcon from "../../../assests/icons/CallIcon";
import Emailicons from "../../../assests/icons/Emailicon";
import TelegramIcom from "../../../assests/icons/TelegramIcom";
import PaymentMethosImg from "../../../assests/images/payment-methods.png";
import { useNavigate } from "react-router-dom";

import "./footer.css";

const Index = () => {
  const navigate = useNavigate();

  return (
    <>
      <div className="footer-container d-flex flex-wrap justify-content-center align-items-center">
        <div className=" me-5 ms-5 d-flex flex-wrap justify-content-between align-items-center w-100 h-100">
          <div className="footer-text" style={{ width: "300px" }}>
            Connect24 Market – это IT платформа будущего для тех, кто хочет
            зарабатывать онлайн, предлагая своим клиентам широкую продуктовую
            линейку, собранную на платформе.
          </div>
          <div>
            <div
              className="footer-active-link c-p"
              onClick={() => navigate("/")}
            >
              Home
            </div>
            <div
              className="footer-link-text c-p"
              onClick={() => navigate(`/contactInformation`)}
            >
              Contact
            </div>
            <div
              className="footer-link-text c-p"
              onClick={() => navigate(`/helpful-information/${1}`)}
            >
              Terms of use
            </div>
            <div
              className="footer-link-text c-p"
              onClick={() => navigate(`/helpful-information/${4}`)}
            >
              Privacy Policy
            </div>
          </div>
          <div>
            <div
              className="footer-link-text c-p"
              onClick={() => navigate(`/helpful-information/${0}`)}
            >
              FAQ
            </div>
            <div
              className="footer-link-text c-p"
              onClick={() => navigate(`/helpful-information/${4}`)}
            >
              How to use marketplace
            </div>
            <div
              className="footer-link-text c-p"
              onClick={() => navigate(`/helpful-information/${5}`)}
            >
              The order of using the services
            </div>
          </div>
          <div>
            <div className="d-flex">
              <div className="m-1">
                <Emailicons />
              </div>
              <div className="footer-icon-text m-1">info@connect 24.com</div>
            </div>
            <div className="d-flex">
              <div className="m-1">
                <CallIcon />
              </div>
              <div className="footer-icon-text m-1">+ 8 800 555 35 35</div>
            </div>
            <div className="d-flex">
              <div className="m-1">
                <TelegramIcom />
              </div>
              <div className="footer-icon-text m-1">Telegram channel</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-payment-methods d-flex flex-wrap justify-content-between align-items-center">
        <div className=" me-5 ms-5 d-flex flex-wrap justify-content-between align-items-center w-100 h-100">
          <div className="payment-methods-text">
            Connect24 <span className="copy-right-footer">© 2021</span>
          </div>
          <div className=" d-flex flex-wrap align-items-center">
            <div className="m-1">
              <img src={PaymentMethosImg} alt="" />
            </div>
            <div className="payment-methods-text m-1">
              By making a payment, you agree to the
              <span
                style={{
                  color: "white",
                  textDecoration: "underline",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                Security Policy
              </span>
              and
              <span
                style={{
                  color: "white",
                  textDecoration: "underline",
                  paddingLeft: "8px",
                  paddingRight: "8px",
                }}
              >
                Payment rules
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Index;
