import React, { useState } from 'react';
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { MDBSwitch, MDBCheckbox } from "mdb-react-ui-kit";
import GoogleIcon from "../../assests/icons/GoogleIcon";
import LinkedinIcon from "../../assests/icons/LinkedinIcon";
import { useNavigate } from "react-router-dom";

function Registration() {

  const [registerAsCheckBox, setRegisterAsCheckBox] = useState(false);
  const [form, setForm] = useState({});
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const setField = (field, value) => {
    setForm({
      ...form,
      [field]: value,
    });

    if (!!errors[field])
      setErrors({
        ...errors,
        [field]: null,
      });
  };

  const validateForm = () => {
    const {
      email,
      password,
      repassword,
      realEstateAgent,
      insuranceAgent,
      financier,
    } = form;
    const newError = {};

    if (!email || email === "") newError.email = "Please enter your email";
    else if (
      !/^([a-zA-Z0-9_\-.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/.test(
        email
      )
    )
      newError.email = "Please enter the proper email";
    if (!password || password === "")
      newError.password = "Please enter your password";
    if (!repassword || repassword === "")
      newError.repassword = "Please re-enter your password";
    else if (password != repassword)
      newError.repassword = "Please enter the same password";

    return newError;
  };

  const handleUserData = (e) => {
    e.preventDefault();

    const formErrors = validateForm();
    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
    } else {
      console.log("form submitted");
      console.log("form", form);
    }
  };

  return (
    <div className="registration-container">
        <div className="login-div">
        <div>
            <Form>
              <Form.Group className="mb-3" controlId="formBasicEmail">
                <Form.Label bsPrefix="form-label">E-mail</Form.Label>
                <Form.Control
                  type="email"
                  value={form.email}
                  onChange={(e) => setField("email", e.target.value)}
                  isInvalid={!!errors.email}
                />
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Пароль</Form.Label>
                <Form.Control
                  type="password"
                  value={form.password}
                  onChange={(e) => setField("password", e.target.value)}
                  isInvalid={!!errors.password}
                />
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Подтвердите пароль</Form.Label>
                <Form.Control
                  type="password"
                  value={form.repassword}
                  onChange={(e) => setField("repassword", e.target.value)}
                  isInvalid={!!errors.repassword}
                />
              </Form.Group>
              <Form.Group
                className="mb-3 form-switch-label"
                controlId="formBasicCheckbox"
                onChange={(e) => setRegisterAsCheckBox(e.target.checked)}
              >
                <MDBSwitch
                  id="flexSwitchCheckDefault"
                  label="Создать бизнес-аккаунт"
                />
              </Form.Group>
              {registerAsCheckBox ? (
                <Form.Group className="mb-3 form-check-box-label">
                  <div className="register-as">Зарегистрироваться как</div>
                  <MDBCheckbox
                    name="flexCheck"
                    label="Агент по недвижимости"
                    value={form.realEstateAgent}
                    onChange={(e) =>
                      setField("realEstateAgent", e.target.checked)
                    }
                    isInvalid={!!errors.realEstateAgent}
                  />
                  <MDBCheckbox
                    name="flexCheck"
                    label="Страховой агент"
                    value={form.insuranceAgent}
                    onChange={(e) =>
                      setField("insuranceAgent", e.target.checked)
                    }
                    isInvalid={!!errors.insuranceAgent}
                  />
                  <MDBCheckbox
                    name="flexCheck"
                    label="Финансист"
                    value={form.financier}
                    onChange={(e) => setField("financier", e.target.checked)}
                    isInvalid={!!errors.financier}
                  />
                </Form.Group>
              ) : (
                ""
              )}
              <div className="user-sign-button">
                <Button type="submit" onClick={handleUserData}>
                  Зарегистрироваться
                </Button>
              </div>
            </Form>
        </div>
        <div className="social-networks-line">
          <div className="divider" style={{ width: "20.5px" }}></div>
            <div className="social-networks">
              или зарегиструйтесь через соцсети
            </div>
            <div className="divider" style={{ width: "20.5px" }}></div>
        </div>
        <div className="social-networks-buttons">
            <Button className="m-1 google-button">
                <GoogleIcon />
            </Button>
            <Button className="m-1 linkedin-button">
                <LinkedinIcon />
            </Button>
        </div>
        <div className="new-user-login" onClick={() => navigate("/login")}>
            Уже есть учетная запись? &nbsp;
            <span className="new-user-login-link">Войти</span>
        </div>
        </div>
    </div>
  )
}

export default Registration