import React from "react";

const Index = () => {
  return (
    <>
      <h1>This is footer for private layout</h1>
    </>
  );
};

export default Index;
