// public layout for routes

import React from "react";

import HeaderComponent from "./Header/index.js";
import FooterComponent from "./Footer";

import "./SideBar/sidebar.css";
import { useLocation } from "react-router-dom";

const Index = ({ children }) => {
  const location = useLocation();

  return (
    <>
      <div>
        <div>
          {location.pathname !== "/login" &&
            location.pathname !== "/registration" && (
              <header>
                <HeaderComponent />
              </header>
            )}
          <main>
            <div className="main-app-container">{children}</div>
          </main>
        </div>
      </div>
      {location.pathname !== "/login" &&
        location.pathname !== "/registration" && <FooterComponent />}
    </>
  );
};

export default Index;
