import React from "react";

const TelegramIcom = () => {
  return (
    <>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M2.48994 9.37213L21.2399 2.27572C21.3076 2.24985 21.3813 2.24412 21.4521 2.25922C21.5229 2.27432 21.5879 2.3096 21.6391 2.36081C21.6903 2.41202 21.7256 2.47696 21.7407 2.54779C21.7558 2.61861 21.75 2.6923 21.7242 2.75994L14.6278 21.5099C14.5995 21.5811 14.5502 21.642 14.4863 21.6843C14.4225 21.7266 14.3472 21.7484 14.2706 21.7467C14.1941 21.7449 14.1199 21.7198 14.058 21.6746C13.9961 21.6294 13.9496 21.5664 13.9246 21.494L10.7653 13.7085C10.7284 13.5982 10.6664 13.498 10.5842 13.4157C10.5019 13.3335 10.4017 13.2715 10.2913 13.2346L2.50588 10.0781C2.43254 10.0537 2.36852 10.0072 2.32261 9.94501C2.27669 9.88283 2.25111 9.80798 2.24936 9.73071C2.24762 9.65343 2.2698 9.5775 2.31286 9.51332C2.35593 9.44913 2.41777 9.39982 2.48994 9.37213V9.37213Z"
          stroke="white"
          stroke-opacity="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M20 4L12 12"
          stroke="white"
          stroke-opacity="0.6"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default TelegramIcom;
